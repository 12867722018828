export class ProviderType {
    public static values: any = {
        "all": [
            "Funeral Home",
            "Crematory",
            "Mortician",
            "Body Art Establishment",
            "Body Art Temporary Event",
            "Body Artist",
            "SLP",
            "AUD",
            "OCAP",
            "HID",
            "INTRP",
            "Doula",
            "Dual AUD/SLP",
            "NAR Training Site",
            "Mort Intern"
        ].sort(),
        "schedInsp": [
            "NAR Training Site",
            "Funeral Home",
            "Crematory",
            "Body Art Establishment"

        ].sort(),
        "initInsp": [
            "Body Art Establishment",
            "Body Art Temporary Event",
            "Funeral Home",
            "Crematory"
        ].sort(),
        "chow": [
            "Funeral Home",
            "Crematory"
        ].sort()
    }
}